import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { StateService } from '@uirouter/core';
import { OpenUniversityCart, OpenUniversityItemState } from 'common-typescript/types';
import _ from 'lodash';
import moment, { Duration } from 'moment';
import { combineLatest, interval, of, switchMap } from 'rxjs';
import { map } from 'rxjs/operators';
import { DowngradedService, ServiceDowngradeMappings, StaticMembers } from 'sis-common/types/angular-hybrid';
import { UuidService } from 'sis-common/uuid/uuid.service';

import { AppErrorHandler } from '../error-handler/app-error-handler';
import { InfoDialogService } from '../info-dialog/info-dialog.service';
import { OpenUniversityCartCustomerService } from '../service/open-university-cart-customer.service';
import { distinctUntilAnyKeyChanged } from '../util/utils';

import { AlertsService, AlertType } from './alerts-ng.service';

@StaticMembers<DowngradedService>()
@Injectable({ providedIn: 'root' })
export class OpenUniversityCartTimerAlertService {

    static downgrade: ServiceDowngradeMappings = {
        moduleName: 'sis-components.alerts.sisOpenUniversityCartTimerAlertService',
        serviceName: 'sisOpenUniversityCartTimerAlertService',
    };

    constructor(private appErrorHandler: AppErrorHandler,
                private alertService: AlertsService,
                private translateService: TranslateService,
                private openUniversityCartCustomerService: OpenUniversityCartCustomerService,
                private stateService: StateService,
                private uuidService: UuidService,
                private infoDialogService: InfoDialogService) {
    }

    private currentTimerIdentifier: string;

    subscribeToCurrentOpenUniversityCartChanges() {
        this.openUniversityCartCustomerService.getCurrentCart()
            .pipe(
                distinctUntilAnyKeyChanged('id', 'state', 'reservedUntilDateTime'),
                switchMap(cart => combineLatest([of(cart), this.openUniversityCartCustomerService.getReservationTimeLeft(cart?.id)])),
                this.appErrorHandler.defaultErrorHandler(),
            )
            .subscribe(([cart, reservationTimeRemaining]) => {
                if (this.shouldInitNewTimer(cart, reservationTimeRemaining)) {
                    this.initTimerAlert(cart, reservationTimeRemaining);
                } else if (this.currentTimerIdentifier) {
                    this.dismissCurrentTimer();
                }
            });
    }

    private shouldInitNewTimer(cart: OpenUniversityCart, reservationTimeLeft: Duration) {
        return cart?.state === 'RESERVED' || (cart?.state === 'TENTATIVE' && reservationTimeLeft?.asSeconds() > 0);
    }

    initTimerAlert(cart: OpenUniversityCart, reservationTimeRemaining: Duration) {
        this.dismissCurrentTimer();
        this.currentTimerIdentifier = this.uuidService.randomUUID();

        this.alertService.addAlert({
            message: this.getMessage(cart.state, reservationTimeRemaining) ?? '',
            type: AlertType.INFO,
            identifier: this.currentTimerIdentifier,
            hideDismissibleButton: true,
            role: 'timer',
            updater: {
                byObservable: interval(1000)
                    .pipe(map(i => moment.duration(reservationTimeRemaining).subtract(i + 1, 'seconds'))),
                updatedMessageProvider: (dismissAlert: Function, timeRemaining: Duration) => {
                    const message = this.getMessage(cart.state, timeRemaining);
                    if (!message) {
                        this.dismissCurrentTimer(dismissAlert);
                        this.timesUpModal();
                        // Cart has expired in backend - this effectively clears cart in frontend
                        this.openUniversityCartCustomerService.refreshCurrentCart();
                    }

                    return message ?? '';
                },
            },
        });
    }

    private getMessage(state: OpenUniversityItemState, timeRemaining: Duration): string | null {
        if (state === 'RESERVED') {
            return this.translateService.instant('PAYMENT_IN_PROGRESS_ALERT');
        }

        if (timeRemaining && timeRemaining.asSeconds() > 0) {
            return this.translateService.instant('PRODUCT_IN_CART_ALERT', { timeRemain: this.formatTimeRemain(timeRemaining) });
        }

        return null;
    }

    private formatTimeRemain(duration: Duration): string {
        return duration.hours() > 0
            ? `${this.addLeadingZero(duration.hours())}:${this.addLeadingZero(duration.minutes())}:${this.addLeadingZero(duration.seconds())}`
            : `${this.addLeadingZero(duration.minutes())}:${this.addLeadingZero(duration.seconds())}`;
    }

    // Duration formats value by default like this: "33:9" (min:sec).
    // This method is used to add leading zero, so the time left would look like this: "33:09" (min:sec).
    private addLeadingZero(value: number): string {
        return _.padStart(`${value}`, 2, '0');
    }

    timesUpModal() {
        const title = this.translateService.instant('OPEN_UNIVERSITY_CART_TIMER_ALERT_MODAL.TITLE');
        const descriptions = [
            this.translateService.instant('OPEN_UNIVERSITY_CART_TIMER_ALERT_MODAL.DESC_1'),
            this.translateService.instant('OPEN_UNIVERSITY_CART_TIMER_ALERT_MODAL.DESC_2'),
        ];
        this.infoDialogService.open({ title, descriptions })
            .then(() => this.stateRedirect());
    }

    dismissCurrentTimer(dismissAlert?: Function) {
        dismissAlert ? dismissAlert() : this.alertService.dismissAlertIfExists(this.currentTimerIdentifier);
        this.currentTimerIdentifier = undefined;
    }

    private stateRedirect() {
        const isCurrentStateInsideOpenUniWizard = this.stateService.is('student.course-unit.open-university-enrolment-wizard');

        if (isCurrentStateInsideOpenUniWizard) {
            return this.stateService.go('student.course-unit.brochure', {}, { custom: { skipConfirmationDialog: true } });
        }
        return this.stateService.reload();
    }
}

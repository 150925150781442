<ng-container *transloco="let t">
  <div class="modal-header">
    <fudis-heading [level]="2">
      <ng-container *ngIf="resultCode === 'EMREX_OK'; else failureTitle">
        {{ t('PLAN.EMREX_RESULT_MODAL.SUCCESS_TITLE') }}
      </ng-container>
      <ng-template #failureTitle>
        {{ t('PLAN.EMREX_RESULT_MODAL.FAILURE_TITLE') }}
      </ng-template>
    </fudis-heading>
    <sis-modal-close-button/>
  </div>

  <div class="modal-body">
    <ng-container *ngIf="resultCode === 'EMREX_OK'; else failureGuidance">
      <p>{{ t('PLAN.EMREX_RESULT_MODAL.SUCCESS_GUIDANCE_1') }}</p>
      <p>{{ t('PLAN.EMREX_RESULT_MODAL.SUCCESS_GUIDANCE_2') }}</p>
    </ng-container>
    <ng-template #failureGuidance>
      <ng-container *ngIf="resultCode.startsWith('SISU')">
        <p>{{ t('PLAN.EMREX_RESULT_MODAL.SISU_FAILURE_GUIDANCE') }}</p>
        <p>{{ t('PLAN.EMREX_RESULT_MODAL.FAILURE_GUIDANCE_2') }}</p>
      </ng-container>
      <ng-container *ngIf="resultCode === 'SISU_SESSION_INVALID'">
        <p>{{ t('PLAN.EMREX_RESULT_MODAL.SESSION_INVALID_GUIDANCE_1') }}</p>
        <p>{{ t('PLAN.EMREX_RESULT_MODAL.SESSION_INVALID_GUIDANCE_2') }}</p>
      </ng-container>
      <ng-container *ngIf="['EMREX_ERROR', 'EMREX_NO_RESULTS', 'EMREX_CANCEL'].includes(resultCode)">
        <p>{{ t('PLAN.EMREX_RESULT_MODAL.FAILURE_GUIDANCE_1') }}</p>
        <p>{{ t('PLAN.EMREX_RESULT_MODAL.FAILURE_GUIDANCE_2') }}</p>
      </ng-container>
      <ng-container *ngIf="resultCode === 'EMREX_PAYLOAD_TOO_LARGE'">
        <p>{{ t('PLAN.EMREX_RESULT_MODAL.PAYLOAD_FAILURE_GUIDANCE_1') }}</p>
        <p>{{ t('PLAN.EMREX_RESULT_MODAL.PAYLOAD_FAILURE_GUIDANCE_2') }}</p>
      </ng-container>
      <ng-container *ngIf="resultCode === 'EMREX_PERSON_MISMATCH'">
        <p>{{ t('PLAN.EMREX_RESULT_MODAL.PERSON_MISMATCH_GUIDANCE_1') }}</p>
        <p>{{ t('PLAN.EMREX_RESULT_MODAL.PERSON_MISMATCH_GUIDANCE_2') }}</p>
      </ng-container>
      <p>{{ t('PLAN.EMREX_RESULT_MODAL.ERROR_CODE') }}: {{ resultCode }}</p>
    </ng-template>
  </div>

  <div class="modal-footer confirm-dialog-footer">
    <sis-button (clicked)="modal.dismiss()">{{ t('CLOSE') }}</sis-button>
  </div>
</ng-container>

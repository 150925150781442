import { ChangeDetectionStrategy, Component, Inject, inject, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ELMOLearner, ELMOLearningOpportunitySpecification, ExternalAttainedStudy } from 'common-typescript/types';
import { LocaleService } from 'sis-common/l10n/locale.service';

export interface EmrexAttainmentDialogValues {
    externalAttainedStudy: ExternalAttainedStudy
}

@Component({
    selector: 'sis-emrex-attainment-dialog',
    templateUrl: './emrex-attainment-dialog.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmrexAttainmentDialogComponent {
    private localeService = inject(LocaleService);

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: EmrexAttainmentDialogValues,
    ) {}

    resolveTitle = (elos: ELMOLearningOpportunitySpecification) => {
        const localizedTitle = elos?.title ? this.localeService.localize(elos.title) : null;
        if (elos?.iscedCode && localizedTitle) return `${elos.iscedCode}, ${localizedTitle}`;
        return localizedTitle;
    };

    resolveFullName(learner?: ELMOLearner): string {
        if (!learner) return '';
        const nameParts = [];
        if (learner.givenNames?.trim()) nameParts.push(learner.givenNames.trim());
        if (learner.familyName?.trim()) nameParts.push(learner.familyName.trim());
        return nameParts.join(' ');
    }
}


<ng-container *transloco="let t">
  <ng-container *ngIf="courseUnitRealisation">
    <p *ngIf="courseUnitRealisation.tweetText" class="key-text">
      {{ courseUnitRealisation.tweetText | localizedString }}
    </p>
    <div class="mb-5 mb-sm-3">
      <sis-tab-content-switch [(currentIndex)]="currentTabIndex"
                              type="pills"
                              [tabs]="tabs">
      </sis-tab-content-switch>
    </div>

    <div [hidden]="currentTabIndex !== 0">
      <sis-description-list [fillByColumn]="false" [columnCount]="3">
        <dl>
          <ng-template #listItem *ngIf="courseUnitRealisation.teachingLanguageUrn | codeLocalizedName | async as teachingLanguage">
            <dt class="sis-label">{{t('FIELD_NAMES.teachingLanguageUrn')}}</dt>
            <dd>{{teachingLanguage}}</dd>
          </ng-template>
          <ng-template #listItem *ngIf="courseUnitRealisation.studyFormat as studyFormat">
            <dt class="sis-label">{{t('COURSE_UNIT_REALISATION_INFO.STUDY_FORMAT')}}</dt>
            <dd>
              <sis-localized-markup-string-view [content]="studyFormat">
              </sis-localized-markup-string-view>
            </dd>
          </ng-template>
          <ng-template #listItem *ngIf="courseUnitRealisation.learningEnvironments?.length  > 0">
            <dt class="sis-label">{{t('FIELD_NAMES.learningEnvironments')}}</dt>
            <dd>
              <p *ngIf="courseUnitRealisation.learningEnvironmentDescription">{{ courseUnitRealisation.learningEnvironmentDescription | localizedString }}</p>
            @for (learningEnvironment of courseUnitRealisation.learningEnvironments; track $index) {
              <a fudisLink [title]="learningEnvironment.name" [external]="true" [href]="learningEnvironment.url"></a>
              }
            </dd>
          </ng-template>
          <ng-template #listItem *ngIf="courseUnitRealisation.learningMaterial as learningMaterial">
            <dt class="sis-label">{{t('FIELD_NAMES.learningMaterial')}}</dt>
            <dd><sis-localized-markup-string-view [content]="learningMaterial"></sis-localized-markup-string-view></dd>
          </ng-template>
          <ng-template #listItem *ngIf="courseUnitRealisation.customCodeUrns as customCodeUrns">
            <dt class="sis-label" translate>CUSTOM_CODE_VALUES.TITLE</dt>
            <dd>
              <sis-custom-code-urns [codeUrnsMap]="customCodeUrns"
                                    [classificationScope]="'course-unit-realisation'">
              </sis-custom-code-urns>
            </dd>
          </ng-template>
          <ng-template #listItem *ngIf="courseUnitRealisation.literature?.length > 0">
            <dt class="sis-label">{{t('FIELD_NAMES.literature')}}</dt>
            <dd>
              <sis-literature *ngFor="let literature of courseUnitRealisation.literature" [literature]="literature"></sis-literature>
            </dd>
          </ng-template>
          <ng-template #listItem *ngIf="courseUnitRealisation.additionalInfo as additionalInfo">
            <dt class="sis-label">{{t('FIELD_NAMES.additionalInfo')}}</dt>
            <dd><sis-localized-markup-string-view [content]="additionalInfo"></sis-localized-markup-string-view></dd>
          </ng-template>
          <ng-template #listItem *ngIf="alternativeCourseUnits$ | async as alternativeCourseUnits">
            @if (alternativeCourseUnits.length > 0) {
              <dt class="sis-label" translate>COURSE_UNIT_REALISATION_INFO.ALTERNATIVE_COURSE_UNITS</dt>
              <dd>
                <p class="sis-guidance" translate>COURSE_UNIT_REALISATION_INFO.ALTERNATIVE_CU_HELP</p>
                @for (courseUnit of alternativeCourseUnits; track courseUnit.id) {
                  <span>{{courseUnit.code}} {{ courseUnit.name | localizedString }} /
                    @if (assessmentItemsByCourseUnitId$ | async; as assessmentItemsByCourseUnitId) {
                      @for (assessmentItem of assessmentItemsByCourseUnitId.get(courseUnit.id); track assessmentItem.id) {
                        <span>{{ assessmentItem.name | localizedString }}</span><span
                        > ({{ assessmentItem.credits | creditRange }}</span>){{$last ? '' : ', '}}
                      }
                    }
                  </span>
                }
              </dd>
            }
          </ng-template>
          <ng-template #listItem>
            <dt class="sis-label">{{t('FIELD_NAMES.responsibilityInfos')}}</dt>
            <dd>
              <sis-responsibility-infos [responsibilityInfos]="courseUnitRealisation.responsibilityInfos"
                                        [roleVisibilityAndOrder]="[
                                      'urn:code:course-unit-realisation-responsibility-info-type:contact-info',
                                      'urn:code:course-unit-realisation-responsibility-info-type:responsible-teacher',
                                      'urn:code:course-unit-realisation-responsibility-info-type:teacher'
                                    ]">
              </sis-responsibility-infos>
            </dd>
          </ng-template>
        </dl>
      </sis-description-list>
      <div *ngIf="courseUnitRealisation.organisations?.length > 0" class="form-group-mimic">
        <sis-organisation-role-share [organisations]="courseUnitRealisation.organisations"
                                     [organisationRoleUrn]="'urn:code:organisation-role:responsible-organisation'"></sis-organisation-role-share>

        <sis-organisation-role-share *ngIf="hasCoordinatingOrganisations()"
                                     [organisations]="courseUnitRealisation.organisations"
                                     [organisationRoleUrn]="'urn:code:organisation-role:coordinating-organisation'"></sis-organisation-role-share>
      </div>
    </div>

    <fudis-grid [hidden]="currentTabIndex !== 1" [rowGap]="'sm'">
      <fudis-grid-item>
        <fudis-dl>
          <fudis-dl-item>
            <fudis-dd>
              <fudis-dl [variant]="'compact'">
                <fudis-dl-item *ngIf="courseUnitRealisation.enrolmentPeriod">
                  <fudis-dt [contentText]="t('SIS_COMPONENTS.COURSE_UNIT_REALISATION_INFO.ENROLMENT_TIME')"></fudis-dt>
                  <fudis-dd [contentText]="courseUnitRealisation.enrolmentPeriod | localDateTimeRange"></fudis-dd>
                </fudis-dl-item>
                <fudis-dl-item *ngIf="courseUnitRealisation.enrolmentAdditionalCancellationEnd">
                  <fudis-dt [contentText]="t('SIS_COMPONENTS.COURSE_UNIT_REALISATION_INFO.ENROLMENT_ADDITIONAL_CANCELLATION_END')"></fudis-dt>
                  <fudis-dd [contentText]="courseUnitRealisation.enrolmentAdditionalCancellationEnd | localDateTimeFormat"></fudis-dd>
                  <fudis-button fudisTooltip [tooltip]="t('SIS_COMPONENTS.COURSE_UNIT_REALISATION_INFO.ENROLMENT_ADDITIONAL_CANCELLATION_END_GUIDANCE')"
                                [tooltipToggle]="true"
                                [icon]="'info-circle'"
                                [variant]="'tertiary'"
                                labelHidden="true"
                                icon="info-circle"
                                size="icon-only"
                                label="tertiary"></fudis-button>
                </fudis-dl-item>
                <fudis-dl-item *ngIf="courseUnitRealisation.lateEnrolmentEnd">
                  <fudis-dt [contentText]="t('SIS_COMPONENTS.COURSE_UNIT_REALISATION_INFO.LATE_ENROLMENT_END' )"></fudis-dt>
                  <fudis-dd [contentText]="courseUnitRealisation.enrolmentAdditionalCancellationEnd | localDateTimeFormat"></fudis-dd>
                  <fudis-button fudisTooltip [tooltip]="t('SIS_COMPONENTS.COURSE_UNIT_REALISATION_INFO.LATE_ENROLMENT_END_GUIDANCE')"
                                [tooltipToggle]="true"
                                [icon]="'info-circle'"
                                [variant]="'tertiary'"
                                labelHidden="true"
                                icon="info-circle"
                                size="icon-only"
                                label="tertiary"></fudis-button>
                </fudis-dl-item>
              </fudis-dl>
            </fudis-dd>
          </fudis-dl-item>
        </fudis-dl>
      </fudis-grid-item>
      <ng-container *ngIf="enrolmentCalculationConfig$ | async as enrolmentCalculationConfig">
        <fudis-grid-item>
          <sis-course-unit-realisation-seats [level]="level + 1" [enrolmentCalculationConfig]="enrolmentCalculationConfig"></sis-course-unit-realisation-seats>
        </fudis-grid-item>
        <fudis-grid-item *ngIf="enrolmentCalculationConfig.requirementPersonRules !== null && enrolmentCalculationConfig.requirementPersonRules.length > 0">
          <div class="form-group-mimic">
            <div role="heading" [attr.aria-level]="level + 1" class="sis-label">{{t("SIS_COMPONENTS.COURSE_UNIT_REALISATION_INFO.REQUIREMENT_PERSON_RULES")}}</div>
            <p class="sis-guidance">{{t("SIS_COMPONENTS.COURSE_UNIT_REALISATION_INFO.REQUIREMENT_PERSON_RULES_HELP")}}</p>
            <ul class="person-rules">
              @for (rule of enrolmentCalculationConfig.requirementPersonRules; track $index) {
                <li>{{ rule | personRequirementRuleName | async}}</li>
              }
            </ul>
          </div>
        </fudis-grid-item>
        <fudis-grid-item *ngIf="enrolmentCalculationConfig.orderingPersonRules !== null && enrolmentCalculationConfig.orderingPersonRules.length > 0">
          <div class="form-group-mimic">
            <div role="heading" [attr.aria-level]="level + 1" class="sis-label" translate>SIS_COMPONENTS.COURSE_UNIT_REALISATION_INFO.ORDERING_PERSON_RULES</div>
            <p class="sis-guidance" translate="SIS_COMPONENTS.COURSE_UNIT_REALISATION_INFO.ORDERING_PERSON_RULES_HELP"></p>
            <ul class="person-rules ordering-person-rules">
              @for (rule of enrolmentCalculationConfig.orderingPersonRules; track $index) {
                <li>{{ rule | personRequirementRuleName | async}}</li>
              }
            </ul>
          </div>
        </fudis-grid-item>
      </ng-container>
    </fudis-grid>

    <div [hidden]="currentTabIndex !== 2">
      <app-student-course-unit-realisation-structure
        *ngIf="courseUnitRealisation.studyGroupSets?.length > 0"
        class="study-sub-group-structure"
        [subGroupLevel]="level +1"
        [courseUnitRealisation]="courseUnitRealisation">
      </app-student-course-unit-realisation-structure>
    </div>
  </ng-container>
</ng-container>

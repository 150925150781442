<div *ngIf="totalItems > 0">
  <table [attr.aria-label]="'ARIA_LABEL.STUDENT_WORKFLOWS' | translate" class="table styled-table">
    <thead>
    <tr>
      <ng-container *ngFor="let column of columns">
        <th class="sis-untouchable" [attr.aria-hidden]="column === 'initiatorType'" *ngIf="column !== 'initiatorType'; else emptyHeader">
          <sis-sortable-table-column-header *ngIf="isSortColumn(column); else defaultColumn"
                                            [name]="('STUDENT_APPLICATIONS.' + column)"
                                            [column]="column"
                                            [(reverse)]="reverse"
                                            [orderKey]="sortColumn"
                                            (orderKeyChange)="setOrder(column)"
                                            ariaLabelType="ALPHABETICAL_ORDER">
          </sis-sortable-table-column-header>
          <ng-template #defaultColumn>{{('STUDENT_APPLICATIONS.' + column) | translate}}</ng-template>
        </th>
        <ng-template #emptyHeader>
          <th class="sis-untouchable" [attr.aria-hidden]="true"></th>
        </ng-template>
      </ng-container>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let workflow of studentWorkflowSearchResult.searchResults; trackBy:workflowById"
        data-cy="workflow-search-result">
      <ng-container *ngFor="let column of columns" [ngSwitch]="column">
        <td [ngClass]="{'sorted-cell': sortColumn === column}"
            [attr.aria-hidden]="column === 'initiatorType'"
            *ngSwitchCase="'name'">
          <a [uiSref]="workflowLinkCreator.state(workflow)"
             [uiParams]="workflowLinkCreator.params(workflow)"
             [uiOptions]="workflowLinkCreator.options"
             data-cy="workflow-name-link">
            <span class="student-workflow-type">{{('STUDENT_APPLICATIONS.TYPE.' + workflow.type) | translate}}:</span>
            <span>{{ $any(workflow).name || (workflow | workflowRelatedStudy | async | entityName | localizedString) || '-' }}</span>
          </a>
          <span *ngIf="workflow.initiatorType === 'STAFF'" class="visually-hidden">
            <ng-container [ngTemplateOutlet]="initiatorText"
                          [ngTemplateOutletContext]="{personId:workflow?.createdByPersonId}"></ng-container>
          </span>
        </td>
        <ng-container *ngSwitchCase="'initiatorType'">
          <td [ngClass]="{'sorted-cell': sortColumn === column}"
              [attr.aria-hidden]="false"
              *ngIf="workflow.initiatorType === 'STAFF'; else emptyCell">
            <sis-icon color="primary" icon="info-circle"></sis-icon>
            <span class="student-initiator">
              <ng-container [ngTemplateOutlet]="initiatorText"
                            [ngTemplateOutletContext]="{personId:workflow?.createdByPersonId}"></ng-container>
            </span>
          </td>
          <ng-template #emptyCell>
            <td [ngClass]="{'sorted-cell': sortColumn === column}"
                [attr.aria-hidden]="true"></td>
          </ng-template>
        </ng-container>
        <ng-container *ngSwitchCase="'studyRight'">
          <td [ngClass]="{'sorted-cell': sortColumn === column}"
              [attr.aria-hidden]="column === 'initiatorType'"
              *ngIf="$any(workflow)?.studyRightId | studyRightName | async as educationName">
            {{educationName | localizedString}}
          </td>
        </ng-container>
        <td [ngClass]="{'sorted-cell': sortColumn === column}"
            [attr.aria-hidden]="column === 'initiatorType'"
            *ngSwitchCase="'studentName'">
          {{workflow.personLastName}}, {{workflow.personFirstNames}}
        </td>
        <td [ngClass]="{'sorted-cell': sortColumn === column}"
            [attr.aria-hidden]="column === 'initiatorType'"
            *ngSwitchCase="'code'">
          {{workflow.code}}
        </td>
        <td [ngClass]="{'sorted-cell': sortColumn === column}"
            [attr.aria-hidden]="column === 'initiatorType'"
            *ngSwitchCase="'state'">
          <span class="workflow-status workflow-status__{{this.isOnStudentPage ? 'student' : 'staff'}}">
            <sis-icon icon="{{getStatusIcon(workflow.state)}}"
                      iconClass="{{getStatusIconClass(workflow.state)}}"></sis-icon>
            <span class="field-value">{{('STUDENT_APPLICATIONS.STATE.' + workflow.state) | translate}}</span>
          </span>
        </td>
        <td [ngClass]="{'sorted-cell': sortColumn === column}"
            [attr.aria-hidden]="column === 'initiatorType'"
            *ngSwitchCase="'lastHandledTime'">
          {{workflow.lastHandledTime | localDateFormat}}
        </td>
        <td [ngClass]="{'sorted-cell': sortColumn === column}"
            [attr.aria-hidden]="column === 'initiatorType'"
            *ngSwitchCase="'lastHandlerPerson'">
          <span class="student-workflow-handler">
            <span *ngIf="workflow.lastHandlerPersonId | publicPerson | async as publicPerson">
              <span class="notranslate">{{ publicPerson.lastName }}, {{publicPerson.firstName }}</span>
                 <sis-button link="true"
                             *ngIf="(workflow.state === 'REQUESTED' || workflow.state === 'IN_HANDLING' || workflow.state === 'SUPPLEMENT_REQUESTED') && !isOnStudentPage"
                             (clicked)="this.editHandler.emit(workflow.id)"
                             [attr.aria-label]="'STUDENT_APPLICATIONS.EDIT_HANDLER' | translate">
                   <sis-tooltip [tooltipBlockElement]="true"
                                [tooltipText]="'STUDENT_APPLICATIONS.EDIT_HANDLER' | translate"
                                [attr.aria-label]="'STUDENT_APPLICATIONS.EDIT_HANDLER' | translate">
                     <sis-icon [icon]="'edit'" [color]="'primary'" class="sis-tooltip-trigger-content"></sis-icon>
                   </sis-tooltip>
                 </sis-button>
            </span>
            <span
              *ngIf="!workflow.lastHandlerPersonId && (workflow.state === 'REQUESTED' || workflow.state === 'IN_HANDLING' || workflow.state === 'SUPPLEMENT_REQUESTED') && !isOnStudentPage">
              <sis-button link="true"
                          (clicked)="this.editHandler.emit(workflow.id)">{{'STUDENT_APPLICATIONS.SET_HANDLER' | translate}}
              </sis-button>
            </span>
          </span>
        </td>
        <td [ngClass]="{'sorted-cell': sortColumn === column}"
            [attr.aria-hidden]="column === 'initiatorType'"
            *ngSwitchCase="'creationTime'">
          {{workflow.creationTime | localDateFormat}}
        </td>
        <td [ngClass]="{'sorted-cell': sortColumn === column}"
            [attr.aria-hidden]="column === 'initiatorType'"
            *ngSwitchDefault>{{workflow[column]}}</td>
      </ng-container>
    </tr>
    </tbody>
  </table>

  <div class="row scroll-target">
    <div class="col-12"
         *ngIf="totalItems > itemsPerPage"
         data-cy="pagination-controls">
      <sis-pagination
        sisScrollIntoViewOnPageChange="div.scroll-target"
        [totalItems]="totalItems"
        [currentPage]="currentPage"
        [itemsPerPage]="itemsPerPage"
        [maxSize]="maxSize"
        (currentPageChange)="onPageChange($event)">
      </sis-pagination>
    </div>
  </div>
</div>

<ng-template #initiatorText let-personId="personId">
  {{ personId && !this.isOnStudentPage
  ? ('STUDENT_APPLICATIONS.INITIATOR_PUBLIC' | translate:{personFullName: (personId | publicPerson | async | personFullName)})
  : ('STUDENT_APPLICATIONS.INITIATOR_ANON' | translate) }}
</ng-template>

<ng-container *transloco="let t">
  <fudis-dialog [size]="'xl'">
    <fudis-heading
      [level]="2">{{ resolveTitle(data?.externalAttainedStudy?.learningOpportunitySpecification) }}
    </fudis-heading>
    <fudis-dialog-content
      *ngIf="data?.externalAttainedStudy?.externalIssuerId | externalIssuer | async as externalIssuer">
      <fudis-grid [rowGap]="'md'">
        <fudis-grid-item>
          <fudis-body-text [variant]="'md-light'">{{ t('EMREX_ATTAINMENT_DIALOG.GUIDANCE') }}</fudis-body-text>
        </fudis-grid-item>
        <fudis-grid-item>
          <fudis-grid [columns]="{ md: 1, lg: 2 }">
            <fudis-dl>
              <fudis-dl-item>
                <fudis-dt [contentText]="t('EMREX_ATTAINMENT_DIALOG.LEARNING_OPPORTUNITY_INSTANCE.STUDENT_NAME')">
                </fudis-dt>
                <fudis-dd>
                  <ng-container *ngIf="resolveFullName(data?.externalAttainedStudy?.learner) as fullName; else emptyStateTpl">
                    {{ fullName }}
                  </ng-container>
                </fudis-dd>
              </fudis-dl-item>
              <fudis-dl-item>
                <fudis-dt [contentText]="t('EMREX_ATTAINMENT_DIALOG.LEARNING_OPPORTUNITY_SPECIFICATION.ISCED_CODE')"/>
                <fudis-dd>
                  <ng-container
                    *ngIf="data?.externalAttainedStudy?.learningOpportunitySpecification?.iscedCode as iscedCode; else emptyStateTpl">
                    {{ iscedCode }}
                  </ng-container>
                </fudis-dd>
              </fudis-dl-item>
              <fudis-dl-item>
                <fudis-dt [contentText]="t('EMREX_ATTAINMENT_DIALOG.ISSUER.IDENTIFIER')"/>
                <ng-container
                  *ngIf="externalIssuer?.identifiers?.length > 0">
                  <fudis-dd
                    *ngFor="let identifier of externalIssuer?.identifiers">
                    {{ identifier.value }}
                  </fudis-dd>
                </ng-container>
                <fudis-dd *ngIf="!externalIssuer?.identifiers?.length">
                  <ng-container [ngTemplateOutlet]="emptyStateTpl"/>
                </fudis-dd>
              </fudis-dl-item>
              <fudis-dl-item>
                <fudis-dt [contentText]="t('EMREX_ATTAINMENT_DIALOG.ISSUER.URL')"/>
                <fudis-dd>
                  <ng-container *ngIf="externalIssuer?.url as url; else emptyStateTpl">
                    <a fudisLink [title]="url" [href]="url" [external]="true"></a>
                  </ng-container>
                </fudis-dd>
              </fudis-dl-item>
              <fudis-dl-item>
                <fudis-dt [contentText]="t('EMREX_ATTAINMENT_DIALOG.LEARNING_OPPORTUNITY_INSTANCE.CREDIT')"/>
                <ng-container
                  *ngIf="data?.externalAttainedStudy?.learningOpportunityInstance?.credit?.length > 0">
                  <fudis-dd
                    *ngFor="let credit of data?.externalAttainedStudy?.learningOpportunityInstance?.credit">
                    {{ t('EMREX_ATTAINMENT_DIALOG.CREDITS', {credits: credit.value}) }}
                  </fudis-dd>
                </ng-container>
                <fudis-dd *ngIf="!data?.externalAttainedStudy?.learningOpportunityInstance?.credit?.length">
                  <ng-container [ngTemplateOutlet]="emptyStateTpl"/>
                </fudis-dd>
              </fudis-dl-item>
              <fudis-dl-item>
                <fudis-dt
                  [contentText]="t('EMREX_ATTAINMENT_DIALOG.LEARNING_OPPORTUNITY_INSTANCE.LANGUAGE_OF_INSTRUCTIONS')"/>
                <fudis-dd>
                  <ng-container
                    *ngIf="data?.externalAttainedStudy?.learningOpportunityInstance?.languageOfInstruction as instructions; else emptyStateTpl">
                    {{ instructions | codeLocalizedName | async }}
                  </ng-container>
                </fudis-dd>
              </fudis-dl-item>
              <fudis-dl-item>
                <fudis-dt [contentText]="t('EMREX_ATTAINMENT_DIALOG.LEARNING_OPPORTUNITY_INSTANCE.GRADING_SCALE')"/>
                <fudis-dd>
                  <ng-container
                    *ngIf="data?.externalAttainedStudy?.learningOpportunityInstance?.gradingScheme as gradingScheme; else emptyStateTpl">
                    {{ gradingScheme | localizedString }}
                  </ng-container>
                </fudis-dd>
              </fudis-dl-item>
              <fudis-dl-item>
                <fudis-dt [contentText]="t('EMREX_ATTAINMENT_DIALOG.LEARNING_OPPORTUNITY_SPECIFICATION.SUBJECT_AREA')"/>
                <fudis-dd>
                  <ng-container
                    *ngIf="data?.externalAttainedStudy?.learningOpportunitySpecification?.subjectArea as subjectArea; else emptyStateTpl">
                    {{ subjectArea }}
                  </ng-container>
                </fudis-dd>
              </fudis-dl-item>
              <fudis-dl-item>
                <fudis-dt [contentText]="t('EMREX_ATTAINMENT_DIALOG.LEARNING_OPPORTUNITY_SPECIFICATION.URL')"/>
                <fudis-dd>
                  <ng-container
                    *ngIf="data?.externalAttainedStudy?.learningOpportunitySpecification?.url as url; else emptyStateTpl">
                    <a fudisLink [title]="url" [href]="url" [external]="true"></a>
                  </ng-container>
                </fudis-dd>
              </fudis-dl-item>
              <fudis-dl-item>
                <fudis-dt
                  [contentText]="t('EMREX_ATTAINMENT_DIALOG.LEARNING_OPPORTUNITY_SPECIFICATION.DESCRIPTION_HTML')"/>
                <fudis-dd>
                  <ng-container
                    *ngIf="data?.externalAttainedStudy?.learningOpportunitySpecification?.descriptionHtml as description; else emptyStateTpl">
                    {{ description }} <!-- TODO: backend should have sanitized markup string before viewing markup here? -->
                  </ng-container>
                </fudis-dd>
              </fudis-dl-item>
              <fudis-dl-item>
                <fudis-dt [contentText]="t('EMREX_ATTAINMENT_DIALOG.LEARNING_OPPORTUNITY_INSTANCE.START')"/>
                <fudis-dd>
                  <ng-container
                    *ngIf="data?.externalAttainedStudy?.learningOpportunityInstance?.start as start; else emptyStateTpl">
                    {{ start }}
                  </ng-container>
                </fudis-dd>
              </fudis-dl-item>
              <fudis-dl-item>
                <fudis-dt [contentText]="t('EMREX_ATTAINMENT_DIALOG.LEARNING_OPPORTUNITY_INSTANCE.STATUS')"/>
                <fudis-dd>
                  <ng-container
                    *ngIf="data?.externalAttainedStudy?.learningOpportunityInstance?.status as status; else emptyStateTpl">
                    {{ status }}
                  </ng-container>
                </fudis-dd>
              </fudis-dl-item>
              <fudis-dl-item>
                <fudis-dt [contentText]="t('EMREX_ATTAINMENT_DIALOG.LEARNING_OPPORTUNITY_INSTANCE.LEVEL')"/>
                <ng-container
                  *ngIf="data?.externalAttainedStudy?.learningOpportunityInstance?.level?.length > 0">
                  <fudis-dd
                    *ngFor="let level of data?.externalAttainedStudy?.learningOpportunityInstance?.level">
                    {{ level.type }} <!-- TODO: what should we view here? -->
                  </fudis-dd>
                </ng-container>
                <fudis-dd *ngIf="!data?.externalAttainedStudy?.learningOpportunityInstance?.credit?.length">
                  <ng-container [ngTemplateOutlet]="emptyStateTpl"/>
                </fudis-dd>
              </fudis-dl-item>
              <fudis-dl-item>
                <fudis-dt [contentText]="t('EMREX_ATTAINMENT_DIALOG.LEARNING_OPPORTUNITY_INSTANCE.GROUPING')"/>
                <fudis-dd>
                  <ng-container [ngTemplateOutlet]="emptyStateTpl"/> <!-- TODO: missing field ELMO.LearningOpportunityInstance.grouping -->
                </fudis-dd>
              </fudis-dl-item>
            </fudis-dl>
            <fudis-dl>
              <fudis-dl-item>
                <fudis-dt [contentText]="t('EMREX_ATTAINMENT_DIALOG.LEARNING_OPPORTUNITY_SPECIFICATION.TITLE')"/>
                <fudis-dd>
                  <ng-container
                    *ngIf="data?.externalAttainedStudy?.learningOpportunitySpecification?.title as title; else emptyStateTpl">
                    {{ title | localizedString }}
                  </ng-container>
                </fudis-dd>
              </fudis-dl-item>
              <fudis-dl-item>
                <fudis-dt [contentText]="t('EMREX_ATTAINMENT_DIALOG.ISSUER.TITLE')"/>
                <fudis-dd *ngIf="externalIssuer?.title">
                  {{ externalIssuer.title | localizedString }}
                </fudis-dd>
                <fudis-dd *ngIf="!externalIssuer?.title">
                  <ng-container [ngTemplateOutlet]="emptyStateTpl"/>
                </fudis-dd>
              </fudis-dl-item>
              <fudis-dl-item>
                <fudis-dt [contentText]="t('EMREX_ATTAINMENT_DIALOG.ISSUER.COUNTRY')"/>
                <fudis-dd>
                  <ng-container *ngIf="externalIssuer.countryUrn; else emptyStateTpl">
                    {{ externalIssuer.countryUrn | codeLocalizedName | async}}
                  </ng-container>
                </fudis-dd>
              </fudis-dl-item>
              <fudis-dl-item>
                <fudis-dt [contentText]="t('EMREX_ATTAINMENT_DIALOG.LEARNING_OPPORTUNITY_INSTANCE.DATE')"/>
                <fudis-dd>
                  <ng-container
                    *ngIf="data?.externalAttainedStudy?.learningOpportunityInstance?.date as date; else emptyStateTpl">
                    {{ date | localDateFormat }}
                  </ng-container>
                </fudis-dd>
              </fudis-dl-item>
              <fudis-dl-item>
                <fudis-dt [contentText]="t('EMREX_ATTAINMENT_DIALOG.LEARNING_OPPORTUNITY_INSTANCE.ENGAGEMENT_HOURS')"/>
                <fudis-dd>
                  <ng-container
                    *ngIf="data?.externalAttainedStudy?.learningOpportunityInstance?.engagementHours as engagementHours; else emptyStateTpl">
                    {{ t('EMREX_ATTAINMENT_DIALOG.ENGAGEMENT_HOURS', {engagementHours}) }}
                  </ng-container>
                </fudis-dd>
              </fudis-dl-item>
              <fudis-dl-item>
                <fudis-dt [contentText]="t('EMREX_ATTAINMENT_DIALOG.LEARNING_OPPORTUNITY_INSTANCE.RESULT_LABEL')"/>
                <fudis-dd>
                  <ng-container
                    *ngIf="data?.externalAttainedStudy?.learningOpportunityInstance?.resultLabel as resultLabel; else emptyStateTpl">
                    {{ resultLabel }}
                  </ng-container>
                </fudis-dd>
              </fudis-dl-item>
              <fudis-dl-item>
                <fudis-dt [contentText]="t('EMREX_ATTAINMENT_DIALOG.LEARNING_OPPORTUNITY_INSTANCE.SHORTENED_GRADING')"/>
                <ng-container
                  *ngIf="data?.externalAttainedStudy?.learningOpportunityInstance?.shortenedGrading as shortenedGrading">
                  <fudis-dd
                    [contentText]="t('EMREX_ATTAINMENT_DIALOG.PERCENTAGE_LOWER', {percentage: shortenedGrading.percentageLowe})"/>
                  <fudis-dd
                    [contentText]="t('EMREX_ATTAINMENT_DIALOG.PERCENTAGE_EQUAL', {percentage: shortenedGrading.percentageEqual})"/>
                  <fudis-dd
                    [contentText]="t('EMREX_ATTAINMENT_DIALOG.PERCENTAGE_HIGHER', {percentage: shortenedGrading.percentageHigher})"/>
                </ng-container>
                <fudis-dd *ngIf="!data?.externalAttainedStudy?.learningOpportunityInstance?.shortenedGrading">
                  <ng-container [ngTemplateOutlet]="emptyStateTpl"/>
                </fudis-dd>
              </fudis-dl-item>
              <fudis-dl-item>
                <fudis-dt [contentText]="t('EMREX_ATTAINMENT_DIALOG.LEARNING_OPPORTUNITY_SPECIFICATION.TYPE')"/>
                <fudis-dd>
                  <ng-container
                    *ngIf="data?.externalAttainedStudy?.learningOpportunitySpecification?.type as type; else emptyStateTpl">
                    {{ type }}
                  </ng-container>
                </fudis-dd>
              </fudis-dl-item>
              <fudis-dl-item>
                <fudis-dt [contentText]="t('EMREX_ATTAINMENT_DIALOG.LEARNING_OPPORTUNITY_SPECIFICATION.DESCRIPTION')"/>
                <fudis-dd>
                  <ng-container
                    *ngIf="data?.externalAttainedStudy?.learningOpportunitySpecification?.description as description; else emptyStateTpl">
                    {{ description }}
                  </ng-container>
                </fudis-dd>
              </fudis-dl-item>
              <fudis-dl-item>
                <fudis-dt [contentText]="t('EMREX_ATTAINMENT_DIALOG.LEARNING_OPPORTUNITY_SPECIFICATION.IDENTIFIER')"/>
                <ng-container
                  *ngIf="data?.externalAttainedStudy?.learningOpportunitySpecification?.identifiers?.length > 0">
                  <fudis-dd
                    *ngFor="let identifier of data?.externalAttainedStudy?.learningOpportunitySpecification?.identifiers">
                    {{ identifier.value }}
                  </fudis-dd>
                </ng-container>
                <fudis-dd *ngIf="!data?.externalAttainedStudy?.learningOpportunitySpecification?.identifiers?.length">
                  <ng-container [ngTemplateOutlet]="emptyStateTpl"/>
                </fudis-dd>
              </fudis-dl-item>
              <fudis-dl-item>
                <fudis-dt [contentText]="t('EMREX_ATTAINMENT_DIALOG.LEARNING_OPPORTUNITY_INSTANCE.IDENTIFIER')"/>
                <ng-container
                  *ngIf="data?.externalAttainedStudy?.learningOpportunityInstance?.identifiers?.length > 0">
                  <fudis-dd
                    *ngFor="let identifier of data?.externalAttainedStudy?.learningOpportunityInstance?.identifiers">
                    {{ identifier.value }}
                  </fudis-dd>
                </ng-container>
                <fudis-dd *ngIf="!data?.externalAttainedStudy?.learningOpportunityInstance?.identifiers?.length">
                  <ng-container [ngTemplateOutlet]="emptyStateTpl"/>
                </fudis-dd>
              </fudis-dl-item>
              <fudis-dl-item>
                <fudis-dt [contentText]="t('EMREX_ATTAINMENT_DIALOG.LEARNING_OPPORTUNITY_INSTANCE.ACADEMIC_TERM')"/>
                <fudis-dd>
                  <ng-container [ngTemplateOutlet]="emptyStateTpl"/> <!-- TODO: missing field ELMO.LearningOpportunityInstance.academicTerm -->
                </fudis-dd>
              </fudis-dl-item>
              <fudis-dl-item>
                <fudis-dt
                  [contentText]="t('EMREX_ATTAINMENT_DIALOG.LEARNING_OPPORTUNITY_INSTANCE.RESULT_DISTRIBUTION')"/>
                <ng-container
                  *ngIf="data?.externalAttainedStudy?.learningOpportunityInstance?.resultDistribution as resultDistribution">
                  <fudis-dd
                    *ngFor="let category of resultDistribution?.category">
                    {{ category.label }}: {{ category.count }}
                  </fudis-dd>
                </ng-container>
                <fudis-dd *ngIf="!data?.externalAttainedStudy?.learningOpportunityInstance?.resultDistribution">
                  <ng-container [ngTemplateOutlet]="emptyStateTpl"/>
                </fudis-dd>
              </fudis-dl-item>
              <fudis-dl-item>
                <fudis-dt [contentText]="t('EMREX_ATTAINMENT_DIALOG.LEARNING_OPPORTUNITY_SPECIFICATION.HAS_PART')"/>
                <fudis-dd>
                  <fudis-dd>
                    <ng-container [ngTemplateOutlet]="emptyStateTpl"/> <!-- TODO: missing field ELMOLearningOpportunitySpecification.hasPart -->
                  </fudis-dd>
                </fudis-dd>
              </fudis-dl-item>
            </fudis-dl>
          </fudis-grid>
        </fudis-grid-item>
      </fudis-grid>
    </fudis-dialog-content>
    <fudis-dialog-actions>
      <fudis-button fudisDialogClose [label]="t('BUTTON.CLOSE')"/>
    </fudis-dialog-actions>
  </fudis-dialog>

  <ng-template #emptyStateTpl>
    {{ t('EMREX_ATTAINMENT_DIALOG.EMPTY_STATE') }}
  </ng-template>
</ng-container>

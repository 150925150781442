import { ChangeDetectionStrategy, Component, ElementRef, inject, ViewChild, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FudisDialogService } from '@funidata/ngx-fudis';
import { ValidatablePlan } from 'common-typescript';
import { CourseUnit, CustomStudyDraft, Education, Module, OtmId } from 'common-typescript/types';
import { LocaleService } from 'sis-common/l10n/locale.service';

import { RadiobuttonTreeNode } from '../../radiobutton-tree/radiobutton-tree.component';

/**
 * Defines what kind of studies are selectable in the modal. By default, everything except the education
 * is selectable.
 */
export interface PlanSelectorConfig {
    disableModuleSelection?: boolean;
    disableCourseUnitSelection?: boolean;
    disableCustomStudyDraftSelection?: boolean;
}

export interface PLanSelectorModalData {
    validatablePlan: ValidatablePlan;
    title: string;
    description?: string;
    initialSelection?: OtmId;
    config?: PlanSelectorConfig;
}

export interface SelectedModule {
    module: Module;
    parentId: OtmId;
}

export interface SelectedCourseUnit {
    courseUnit: CourseUnit;
    parentId: OtmId;
}

export interface SelectedCustomStudyDraft {
    customStudyDraft: CustomStudyDraft;
    parentId: OtmId;
}

export type SelectedStudy = SelectedModule | SelectedCourseUnit | SelectedCustomStudyDraft;

export function planSelectorModalOpener(): (data: PLanSelectorModalData) => MatDialogRef<PlanSelectorModalComponent, SelectedStudy> {
    const dialogService = inject(FudisDialogService);
    return data => dialogService.open(PlanSelectorModalComponent, { data });
}

@Component({
    selector: 'sis-plan-selector-modal',
    templateUrl: './plan-selector-modal.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlanSelectorModalComponent {

    readonly data: PLanSelectorModalData = inject(MAT_DIALOG_DATA);

    private readonly dialogService = inject(FudisDialogService);
    private readonly localeService = inject(LocaleService);

    readonly rootNode: RadiobuttonTreeNode<SelectedStudy>;
    selectedStudy: SelectedStudy;
    selectFailed = false;

    @ViewChild('errorNotification', { read: ElementRef }) errorNotification: ElementRef;

    private get validatablePlan() {
        return this.data.validatablePlan;
    }

    private get config() {
        return this.data.config;
    }

    constructor() {
        const { rootModule: education } = this.validatablePlan;
        this.rootNode = {
            id: education.id,
            value: null,
            label: this.localeService.localize(education.name),
            unselectable: true,
            children: this.createModuleChildren(education),
        };
    }

    onSelect() {
        if (this.selectedStudy) {
            this.dialogService.close(this.selectedStudy);
        } else {
            this.selectFailed = true;
            if (this.errorNotification?.nativeElement?.focus) {
                this.errorNotification.nativeElement.focus();
            } else {
                // Notification is not visible yet, focus with delay
                setTimeout(() => this.errorNotification?.nativeElement?.focus?.());
            }
        }
    }

    private createModuleNode(module: Module, parentId?: OtmId): RadiobuttonTreeNode<SelectedStudy> {
        return this.checkInitialSelection({
            id: module.id,
            value: { module, parentId },
            label: this.localeService.localize(module.name),
            unselectable: !!this.config?.disableModuleSelection,
            children: this.createModuleChildren(module),
        });
    }

    private createCourseUnitNode(courseUnit: CourseUnit, parentId: OtmId): RadiobuttonTreeNode<SelectedStudy> {
        return this.checkInitialSelection({
            id: courseUnit.id,
            value: { courseUnit, parentId },
            label: `${courseUnit.code}, ${this.localeService.localize(courseUnit.name)}`,
            unselectable: !!this.config?.disableCourseUnitSelection,
        });
    }

    private createCustomStudyDraftNode(customStudyDraft: CustomStudyDraft, parentId: OtmId): RadiobuttonTreeNode<SelectedStudy> {
        return this.checkInitialSelection({
            id: customStudyDraft.id,
            value: { customStudyDraft, parentId },
            label: customStudyDraft.name,
            unselectable: !!this.config?.disableCustomStudyDraftSelection,
        });
    }

    private createModuleChildren(module: Module | Education) {
        return [
            ...this.validatablePlan.getSelectedModulesUnderModule(module)
                .map(childModule => this.createModuleNode(childModule, module.id)),
            ...this.validatablePlan.getCourseUnitsOrSubstitutingCourseUnitsForModule(module)
                .map(courseUnit => this.createCourseUnitNode(courseUnit, module.id)),
            ...this.validatablePlan.getSelectedCustomStudyDraftsByParentModuleId(module.id)
                .map(studyDraft => this.createCustomStudyDraftNode(studyDraft, module.id)),
        ];
    }

    private checkInitialSelection(node: RadiobuttonTreeNode<SelectedStudy>): RadiobuttonTreeNode<SelectedStudy> {
        if (node.id === this.data.initialSelection) {
            this.selectedStudy = node.value;
        }
        return node;
    }
}
